import FinancialData from "../../assets/landing/home/financial_data.svg";

interface SubscriberVerificationComponentProps {
  id: string;
  name: string;
  logo: string;
  loading: boolean;
  onConfirm: () => void;
  onReject: () => void;
}

export default function SubscriberVerificationComponent({
  id,
  name,
  logo,
  loading,
  onConfirm,
  onReject,
}: SubscriberVerificationComponentProps) {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col md:flex-row max-w-4xl rounded-2xl overflow-hidden shadow-lg p-16 bg-white">
        <div className="md:w-1/2 mb-4 md:mb-0 flex flex-col">
          <div className="flex-1 mb-4">
            <div className="text-center">
              <img
                src={logo}
                alt="Product Logo"
                className="h-16  mx-auto mb-4"
              />
              <div className="mb-4">
                <h2 className="text-center text-2xl font-semibold">{name}</h2>
                <p className="font-semibold text-center text-sm">
                  Release Updates
                </p>
              </div>
            </div>
            <p className="text-gray-700 text-base mb-6 text-justify">
              Thank you for your interest in <b>{name}</b> updates! To ensure we
              only send you information that you find valuable, please confirm
              your subscription. Click 'Confirm' to receive the latest news and
              updates about <b>{name}</b>, or choose 'Reject' if you do not wish
              to subscribe.
            </p>
          </div>
          <div className="flex justify-center">
            <button
              onClick={onConfirm}
              disabled={loading}
              className={
                loading
                  ? "bg-gray-100 text-gray-700 font-bold py-2 px-4 rounded"
                  : "bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
              }
            >
              Confirm
            </button>
            <button
              onClick={onReject}
              disabled={loading}
              className={
                loading
                  ? "ml-4 bg-gray-100 text-gray-700 font-bold py-2 px-4 rounded"
                  : "ml-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
              }
            >
              Reject
            </button>
          </div>
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          <img src={FinancialData} alt="" className="h-full" />
        </div>
      </div>
    </div>
  );
}
